import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const cta = ({ href }) => (
  <a href={href} alt="Expert fissures Franck NOURY">
    <StaticImage
      className="rounded-lg shadow-lg"
      src="../../../images/cta/CTA Franck NOURY.png"
      alt="Franck NOURY"
    />
  </a>
);

export default cta;
