import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const cta = ({ href }) => (
  <a href={href} alt="GeoStudy">
    <StaticImage
      className="rounded-lg shadow-lg"
      src="../../../images/cta/CTA Etude de sol Geostudy.png"
      alt="GeoStudy"
    />
  </a>
);

export default cta;
