import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const KdpImage = ({ href }) => (
  <a href={href} alt="Demandez un kit">
    <StaticImage
      className="rounded-lg shadow-lg mt-6"
      src="../../images/CTA-Kit-fissuration.png"
      alt="Demande de kit"
    />
  </a>
);

export default KdpImage;
